import { Alert } from '@/components/Alert'
import { Button, Card, Stack, Text, Title } from '@mantine/core'
import { useWeb3React } from '@web3-react/core'
import Image from 'next/image'
import { FC, useEffect } from 'react'

interface IProps {
  onClickBack: any
  handleOnClickSignUp: any
  onClickMetamaskSign: any
  onClickWalletConnectSign: any
  message?: string
}

const WalletSelectForm: FC<IProps> = ({ onClickMetamaskSign, onClickWalletConnectSign, onClickBack, message }) => {
  const { account, deactivate } = useWeb3React()

  useEffect(() => {
    if (account) deactivate()
  }, [])

  return (
    <Card className="w-[500px] h-[550px] md:!w-full md:!h-fit !p-14 md:!p-0 flex flex-col justify-center md:justify-start items-center md:items-start">
      <Button
        styles={{ label: { color: '#404040' } }}
        size="compact-md"
        onClick={onClickBack}
        leftSection={<Image src="/svg/icons/arrow-left.svg" height={12} width={12} alt="arrow-left" />}
        variant="transparent"
      >
        Back
      </Button>
      <Stack className="mt-8 md:mt-4">
        <Title order={1} className="text-center md:hidden">
          Connect your wallet
        </Title>
        {/* Mobile */}
        <Title order={2} className="text-left hidden md:block">
          Connect your wallet
        </Title>
        <Text size="md" className="text-center md:text-left" styles={{ root: { color: '#667085' } }}>
          Choose a provider to connect an existing wallet or create a new one.
        </Text>
      </Stack>
      <Stack className="mt-8" w="100%">
        <div className="md:hidden">
          <Button
            leftSection={<Image alt="metamask-icon" src="/svg/wallet-icons/metamask-icon.svg" width={35} height={35} />}
            size="xl"
            fw={500}
            fullWidth
            variant="light"
            onClick={onClickMetamaskSign}
          >
            Metamask
          </Button>
        </div>
        <Button
          variant="light"
          fw={500}
          leftSection={
            <Image alt="wallet-connect-icon" src="/svg/wallet-icons/wallet-connect-icon.svg" width={35} height={35} />
          }
          size="xl"
          color="tertiary"
          onClick={onClickWalletConnectSign}
        >
          Wallet Connect
        </Button>
        {message && (
          <Alert variant="danger" className="mt-5 text-base leading-6 font-medium py-3">
            {message}
          </Alert>
        )}
      </Stack>
    </Card>
  )
}
export default WalletSelectForm
