/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-expressions */
import { Card } from '@/components-v2'
import { yupResolver } from '@hookform/resolvers/yup'
import { Button, Flex, Text, TextInput, Title } from '@mantine/core'
import { IconArrowLeft } from '@tabler/icons-react'
import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import * as Yup from 'yup'

const validationSchema = Yup.object().shape({
  code: Yup.string().required('Verification code is required')
})

const CardEmailOTP = ({ onClickBack, onClickSendCode, error, onRequestCode, email, resetError, sending, disabled }) => {
  // const [counter, setCounter] = useState(10)

  // useEffect(() => {
  //   if (error) {
  //     counter > 0 && setTimeout(() => setCounter(counter - 1), 1000)
  //     resetError()
  //   }
  // }, [error, counter])
  const [disabledResend, setDisabledResend] = useState(false)
  const [countdown, setCountdown] = useState(0)

  const {
    handleSubmit,
    control,
    watch,
    reset,
    setError,
    formState: { errors }
  } = useForm({
    defaultValues: {
      code: ''
    },
    resolver: yupResolver(validationSchema)
  })

  useEffect(() => {
    setError('code', null)
    resetError()
  }, [watch('code')])

  const handleOnClickRequestCode = () => {
    setDisabledResend(true)
    setCountdown(60)
    reset()
    onRequestCode()
  }

  useEffect(() => {
    let interval = null
    if (countdown > 0) {
      interval = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1)
      }, 1000)
    } else if (countdown === 0) {
      setDisabledResend(false)
    }
    return () => clearInterval(interval)
  }, [countdown])

  return (
    <Card className="w-[500px] h-[550px] md:!w-full md:!h-fit !p-14 md:!p-0 flex flex-col justify-center md:justify-start items-center md:items-start">
      <Button
        styles={{ label: { color: '#404040' } }}
        w="fit-content"
        variant="transparent"
        size="compact-md"
        onClick={onClickBack}
        leftSection={<IconArrowLeft size={14} />}
      >
        Back
      </Button>

      <Title lineClamp={2} order={2} className="text-center max-w-full mt-8 md:mt-4 md:text-left">
        Enter the code we just sent to <br className="md:hidden" />
        <span className="break-all">{email}</span>
      </Title>

      <form onSubmit={handleSubmit(onClickSendCode)} className="w-full mt-8">
        <Controller
          control={control}
          name="code"
          render={({ field }) => (
            <TextInput
              classNames={{ label: 'font-semibold' }}
              size="md"
              label="Verification Code"
              placeholder="123456"
              {...field}
              error={error || errors?.code?.message}
            />
          )}
        />

        <Button size="md" fullWidth mt="lg" type="submit" loading={disabled} disabled={disabled}>
          Verify and Continue
        </Button>
      </form>
      <Flex gap="sm" mt="lg" align="center" className="md:w-full md:justify-center">
        <Text>Didn't receive anything?</Text>
        <Button
          size="compact-sm"
          variant="default"
          onClick={handleOnClickRequestCode}
          disabled={sending || disabledResend}
          loading={sending}
          className="disabled:opacity-80"
          fw={500}
        >
          <p>Resend Code {countdown > 0 && !sending && `(${countdown})`}</p>
        </Button>
      </Flex>
      {/* <div className="mt-4 flex flex-col">
        {!error ? null : counter !== 0 ? (
          <TypographyV2 variant="subtitle2" className="text-center">
            Resend OTP Code in: {counter}
          </TypographyV2>
        ) : (
          <TypographyV2 variant="subtitle2" className="text-center">
            Request a new OTP
          </TypographyV2>
        )}
        {counter === 0 ? (
          <Button className="mt-2" onClick={handleOnClickRequestCode} fullWidth>
            Resend Verification Code
          </Button>
        ) : null}
      </div> */}
    </Card>
  )
}

export default CardEmailOTP
